'use strict';
const $ = require('jQuery');
window.$ = window.jQuery = $;
// import * as PIXI from 'pixi.js';
// import * as filters from 'pixi-filters';
//import { isInterfaceDeclaration } from 'typescript'; //不要
import Vivus from 'Vivus';
//import desvg from 'desvg';
//import 'slick-carousel';
//const jQuery = require('jQuery');
//const TWEEN = require('@tweenjs/tween.js')
//const app = new PIXI.Application();
const keyLocalStorage = 'whike-theme-mode';

let minWidth = 767;
let deviceID;
let deviceCaseID;

$(function() {
    deSVG('.title', true);
    deSVG('.logo', true);
    init();
    //darkmord
});
const init = ()=>{

    $('.news-container').slick({
        slidesToShow: 3,
        dots: true
    });
    mousemove();
    if($("#casestudy").length){
        freedomSetting()
        topScrollEffects();
        $(window).on("scroll",topScrollEffects);
    }
    $(window).on('scroll', scrollFader);
    scrollFader();
    contactSetting();
    switcherSetting();
    darkmodeSetting();
    $(window).on('resize',deviceChange);
    mainVisual();
    if($("#wipe").length){
        setTimeout(wipe,500);
    }
    deviceChange();
    menuSetting();
}
const menuSetting = ()=>{
    $('.btn').on('click', function() {
        if(!$(this).hasClass('active')){
            $('.inner','#menu').show().animate({opacity:1},500);
        } else {
            $('.inner','#menu').animate({opacity:0},500,()=>{
                $('.inner','#menu').hide();
            });
        }
        $(this).toggleClass('active');
        return false;
    });
    $('a[href^="#"]','#menu').on('click',(e)=>{
        $('.btn','#menu').trigger('click');
        $('#freedom').addClass('finished');
        let speed = 1000;
        let href= $(e.target).attr("href");
        let target = $(href == "#" || href == "" ? 'html' : href);
        let position = target.offset().top;
        $("html, body").animate({scrollTop:position}, speed, "swing");
        return false;
      });
}
const deviceChange = ()=>{
    console.log( $(window).innerWidth() );
    if( $(window).innerWidth() > minWidth ){
        $('body').addClass('pc');
        $('body').removeClass('sp');
    } else {
        $('body').removeClass('pc');
        $('body').addClass('sp');
    }
    deviceID = setTimeout(mainVisual,300);
    deviceCaseID = setTimeout(caseStudyForMobile,300);
}
const caseStudyForMobile = ()=>{
    if( $(window).innerWidth() > minWidth ){
        $('ul','#casestudy').slick('unslick');
    } else {
        $('.slide-inner',"#casestudy").removeClass('.sc-fixed');
        $('.slide-inner',"#casestudy").removeClass('.sc-bottom');
        $('ul','#casestudy').css({transform: "translate3d(0px, 0px, 0px)"});
        if(!$('ul','#casestudy').hasClass('slick-initialized')){
            $('ul','#casestudy').slick({
                slidesToShow: 1,
            });
        }
    }
}

const wipe = ()=>{
    $(".invert","#wipe").css({opacity:1}).animate({height:"100vh"},550,()=>{
        $('body').removeClass('overlay')
    });
}

const mainVisual = ()=>{
    if($("#main").length){
        let url;
        console.log($(window).innerWidth() +":"+minWidth)
        if( $(window).innerWidth() > minWidth ){
            url = 'assets/images/title_lite.svg';
        } else {
            url = 'assets/images/title_lite_sp.svg';
        }
        $.ajax({
            type:'GET',
            url: url,//svgファイルの場所
            success:function(data){
                setTimeout(function(){
                    mainSvgAnimation(data);
                },1000);
            }
        });
    }
}
const mainSvgAnimation = function(data){
    let type = "";
    if( $(window).innerWidth() > minWidth ){
        type = "pc";
    } else {
        type = "sp";
    }
    if( $('svg').length ){
        if( ( type == "pc" && $('svg','#main').hasClass('pc') )
        || ( type == "sp" && $('svg','#main').hasClass('sp') ) ){
            return;
        }
        $('svg','#main').remove();
    }
    let svgData = $(data).find('svg');
    $(svgData).addClass(type)

    $('#main').prepend(svgData);//吐き出したいIDの場所に設置
    let letters = new Vivus('layer1',//動かしたいSVGのID名
    {
        type: 'sync', //1パスずつ書く
        start: 'inViewport', //ビューポート内に表示されたらスタート
        duration: 100, //速さ
         animTimingFunction:Vivus.EASE_OUT//イージング 
    });
    let inner = new Vivus('layer2',//動かしたいSVGのID名
    {
        type: 'delayed', //1パスずつ書く
        start: 'manual', //ビューポート内に表示されたらスタート
        duration: 200, //速さ
         animTimingFunction:Vivus.EASE_OUT//イージング 
    });
    let guide = new Vivus('layer3',//動かしたいSVGのID名
    {
        type: 'sync', //1パスずつ書く
        start: 'inViewport', //ビューポート内に表示されたらスタート
        duration: 100, //速さ
         animTimingFunction:Vivus.EASE_OUT//イージング 
    });
} 


const contactSetting = ()=>{
    $(".close",".complete").on("on",(e)=>{
        $(".complete").animate({opacity:0},500,()=>{
            $(".complete").hide();
        });
    });
    $(".submit").on("click",(e)=>{
        e.preventDefault();
        if($('input[name="name"]').val() != "" || $('input[name="email"]').val() != "" ){
            let obj = document.forms["cotact"];
            let url = "/path/to/url";
            $.ajax("/path/to/url", {
                type: 'post',
                data: $(obj).serialize(), 
                dataType: 'json'
            }).done(function(data) {
                console.log("成功");
            }).fail(function(data) {
                console.log("失敗");
            });
        } else {

        }
    });
}

const freedomSetting = ()=>{
    $(window).on("scroll",()=>{ 
        if($('#freedom').hasClass('finished')) { return false; } 
        // console.log( "[0] " + $(window).scrollTop());
        // console.log( "[1] " + $("#freedom").outerHeight());
        // console.log( "[2] " + $("#freedom").offset().top);
        if( $(window).scrollTop() >= $("#freedom").offset().top  ){
            $('#freedom').addClass('aninmation');
            $(window).scrollTop($("#freedom").offset().top);
            $("body").addClass("overlay");
            // console.log( "[3] treggered" );
            setTimeout(()=>{
                $("body").removeClass("overlay");
                $('#freedom').addClass('finished');
            },3000);
        }
    });
    
    $("a",".casestudy-list").on("click",(e)=>{
        var a = $(e.target).parent().parent()
        $("body").addClass("overlay");
        $("img","#overlay").attr("src", $("img",$(a)).data("image") );
        $(".caption","#overlay").text( $(".caption",$(a)).text() );
        $(".client-name","#overlay").text( $(a).data("client") );
        console.log($(a).data("client"));

        $("#overlay").show().animate({opacity:1},500);
        console.log($(a));
        console.log( $(".caption",$(a)).text() );
        console.log($(this));
        return false;
    });
    $(".close","#overlay").on("click",(e)=>{
        //$("#overlay").removeClass("active");
        $("#overlay").animate({opacity:0},500,()=>{
            $("#overlay").hide();
            $("body").removeClass("overlay");
        });

        return false;
    });
}

const topScrollEffects = ()=>{
    if($('body').hasClass('sp')){
        return;
    }
    // console.log('topScrollEffects fired')
    var inside = $("#casestudy").height()/2;
    var currentPosPx = $(window).scrollTop() - $("#casestudy").offset().top;
    var per = currentPosPx/inside;
    var endPos = $("#casestudy").width() - $("ul",".slide-inner").width();
    if( $(window).scrollTop() >= $("#casestudy").offset().top && $(window).scrollTop() <= $("#casestudy").offset().top + $("#casestudy").height()/2 ){
        $(".slide-inner","#casestudy").addClass("sc-fixed").removeClass("sc-bottom");
        $("ul",".slide-inner").css( { transform:  "translate3d("+per*endPos+"px,0,0)" } );
    } else if($(window).scrollTop() > $("#casestudy").offset().top + $("#casestudy").height()/2) {
        $(".slide-inner","#casestudy").removeClass("sc-fixed").addClass("sc-bottom");
    } else {
        $(".slide-inner","#casestudy").removeClass("sc-bottom").removeClass("sc-fixed");
        $("ul",".slide-inner").css( { transform:  "translate3d(0,0,0)" } );
    }
}
const scrollFader = ()=>{
    $('.effect-fade').each(function(){
        var elemPos = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > elemPos - windowHeight){
         $(this).addClass('effect-scroll');
        }
    });
}
const mousemove = ()=>{
    $('.close','#overlay').css({'--x': `-34px`});
    $('.close','#overlay').css({'--y': `-34px`});
    $(document).on('mousemove', function (e) {
        $(".cursor").css({"transform":'translate(' + e.pageX + 'px, ' + ( e.pageY  - $(window).scrollTop() ) + 'px)'});
    });
    $('.close','#overlay').on('mousemove', function (e) {
        // const x = e.pageX - e.target.offsetLeft
        // const y = e.pageY - e.target.offsetTop
        const x = e.offsetX - 34;
        const y = e.offsetY - 34;
        e.target.style.setProperty('--x', `${ x }px`)
        e.target.style.setProperty('--y', `${ y }px`)
    });
    $('.close','#overlay').on('mouseout', function (e) {
        e.target.style.setProperty('--x', `-34px`)
        e.target.style.setProperty('--y', `-34px`)
    });
    
    $('h2','#service').on('mousemove', function (e) {
        // const x = e.pageX - e.target.offsetLeft
        // const y = e.pageY - e.target.offsetTop
        const x = e.offsetX - 34;
        const y = e.offsetY - 34;
        e.target.style.setProperty('--x', `${ x }px`)
        e.target.style.setProperty('--y', `${ y }px`)
    });
    $('h2','#service').on('mouseout', function (e) {
        e.target.style.setProperty('--x', `0px`)
        e.target.style.setProperty('--y', `0px`)
    });
}

const switcherSetting = ()=>{
    $('li','.switcher').on('click',(e)=>{
        if($(e.target).data('mode') == "light") {
            console.log($(e.target).data('mode'))
            $(".dark-mode").removeClass("active");
            $(".light-mode").addClass("active");
            $("body").addClass("light")
            $("body").removeClass("dark")
            localStorage.setItem(keyLocalStorage,'light');
        } else if($(e.target).data('mode') == "dark") {
            console.log($(e.target).data('mode'))
            $(".light-mode").removeClass("active");
            $(".dark-mode").addClass("active");
            $("body").addClass("dark")
            $("body").removeClass("light")
                localStorage.setItem(keyLocalStorage,'dark');
        }
    })
}

const darkmodeSetting = ()=>{
    console.log(localStorage.getItem(keyLocalStorage))
    let mode = 'light';
    if( localStorage.getItem(keyLocalStorage) != null ){
        mode = localStorage.getItem(keyLocalStorage);
    } else if ( window.matchMedia('(prefers-color-scheme: dark)').matches ) { // Dark
        mode = 'dark';
    } else { // Light
        mode = 'light';
    }
    if( mode == 'dark' ){
        $(".light-mode").removeClass("active");
        $(".dark-mode").addClass("active");
        console.log("dark");
        $("body").addClass("dark")
        $("body").removeClass("light")
} else {
        $(".dark-mode").removeClass("active");
        $(".light-mode").addClass("active");
        console.log("light");
        $("body").addClass("light")
        $("body").removeClass("dark")
    }
}


